import React, { useState } from "react";
import PropTypes from "prop-types";
import { ImageType, TextType } from "@utils/types";
import Image from "@ui/image";
import Anchor from "@ui/anchor";
import PortfolioModal from "@components/modal-portfolio";

const PortfolioCard = ({ title, client, tags, image, path, description }) => {
    const [show, setShow] = useState(false);
    return (
        <>
            <div
                className="rn-portfolio"
                onClick={() => setShow(true)}
                onKeyPress={() => setShow(true)}
                role="button"
                tabIndex="-1"
            >
                <div className="inner">
                    <div className="thumbnail">
                            <Image src={image.src} alt={image?.alt || title} />
                    </div>
                    <div className="content">
                        <div className="category-info">
                            <div className="category-list">
                                {client}
                            </div>
                            <div className="tag">
                                {tags[0]}
                            </div>
                        </div>
                        <div className="title">
                                {title}
                        </div>
                    </div>
                </div>
            </div>
            <PortfolioModal
                show={show}
                setShow={setShow}
                title={title}
                description={description}
                image={image}
                tags={tags}
                path={path}
                client={client}
            />
        </>
    );
};

PortfolioCard.propTypes = {
    title: PropTypes.string.isRequired,
    client: PropTypes.string.isRequired,
    tags: PropTypes.arrayOf(PropTypes.string),
    image: PropTypes.shape(ImageType).isRequired,
    path: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
};

export default PortfolioCard;
